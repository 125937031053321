/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { GatsbyBrowser } from "gatsby"

// You can delete this file if you're not using it
const Cookies = require("js-cookie")
const stripTrailingSlashes = (url: string) =>
  url.substr(-1) === "/" && url !== "/" ? url.slice(0, -1) : url
const SINGLE_LANGUAGE_PAGE = ["uganda", "brazil", "user-agreement"]
const DEFAULT_LANGUAGE = "en"

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  const params = new URL(window.location as unknown as string).searchParams
  const referrer = document.referrer
  const expires = 30 * 24 * 60 * 60 * 1000

  // TODO: fetch urls from strapi after BTB/BTC separation
  const activeLang = Cookies.get("activeLang")

  const path = stripTrailingSlashes(window.location.pathname).split("/").pop()

  const isBusinessPage = SINGLE_LANGUAGE_PAGE.includes(path || "")

  if (
    !isBusinessPage &&
    activeLang &&
    activeLang !== DEFAULT_LANGUAGE &&
    window.location.pathname.split("/")[1] !== activeLang
  ) {
    window.location.replace("/" + activeLang + window.location.pathname)
  }

  if (params.has("marketingCampaign")) {
    const value = decodeURIComponent(params.get("marketingCampaign") || "")
    Cookies.set("marketingCampaign", value, {
      expires: expires,
    })
    params.delete("marketingCampaign")
    // reload without params
    window.location.href = window.location.href.split("?")[0]
  }

  // url w/h get params
  if (
    !Cookies.get("pageReferrer") &&
    referrer.indexOf(document.location.hostname) === -1 &&
    referrer.indexOf("lolo") === -1
  ) {
    Cookies.set("pageReferrer", referrer, {
      expires: expires,
      secure: true,
    })
  }

  if (!Cookies.get("pageFirstSeen")) {
    const fullUrl =
      "https://" +
      document.location.host.replace("www.", "") +
      document.location.pathname
    Cookies.set("pageFirstSeen", stripTrailingSlashes(fullUrl), {
      expires: expires,
      secure: true,
    })
  }
}
