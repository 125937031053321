exports.components = {
  "component---src-pages-home-404-es-tsx": () => import("./../../../src/pages/home/404.es.tsx" /* webpackChunkName: "component---src-pages-home-404-es-tsx" */),
  "component---src-pages-home-404-tsx": () => import("./../../../src/pages/home/404.tsx" /* webpackChunkName: "component---src-pages-home-404-tsx" */),
  "component---src-pages-home-blog-es-tsx": () => import("./../../../src/pages/home/blog.es.tsx" /* webpackChunkName: "component---src-pages-home-blog-es-tsx" */),
  "component---src-pages-home-blog-tsx": () => import("./../../../src/pages/home/blog.tsx" /* webpackChunkName: "component---src-pages-home-blog-tsx" */),
  "component---src-pages-home-driver-es-tsx": () => import("./../../../src/pages/home/driver.es.tsx" /* webpackChunkName: "component---src-pages-home-driver-es-tsx" */),
  "component---src-pages-home-driver-tsx": () => import("./../../../src/pages/home/driver.tsx" /* webpackChunkName: "component---src-pages-home-driver-tsx" */),
  "component---src-pages-home-index-es-tsx": () => import("./../../../src/pages/home/index.es.tsx" /* webpackChunkName: "component---src-pages-home-index-es-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-home-strapi-partner-page-slug-tsx": () => import("./../../../src/pages/home/{strapiPartnerPage.slug}.tsx" /* webpackChunkName: "component---src-pages-home-strapi-partner-page-slug-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-es-tsx": () => import("./../../../src/pages/terms-and-conditions.es.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-es-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-terms-and-conditions-usa-es-tsx": () => import("./../../../src/pages/terms-and-conditions-usa.es.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-usa-es-tsx" */),
  "component---src-pages-terms-and-conditions-usa-tsx": () => import("./../../../src/pages/terms-and-conditions-usa.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-usa-tsx" */),
  "component---src-pages-user-agreement-tsx": () => import("./../../../src/pages/user-agreement.tsx" /* webpackChunkName: "component---src-pages-user-agreement-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

